import React, { useState, useEffect, useCallback } from 'react';

import { XCircleFill, ExclamationCircleFill, HouseFill, Check2Circle, Infinity, Check, X, ArrowClockwise } from 'react-bootstrap-icons';
import { Modal, Tooltip, DatePicker, ConfigProvider, Switch } from 'antd';
import { ButtonsFlex, InvoicePaid, InvoiceIssued, ReadyForInvoicing, IconButton, DocsOffice, Attention, Submited } from './components/styles';
import { ButtonsWrapper } from '../../styles/buttons';

import ordersService from '../../services/OrdersService';
import AddNewOrder from './AddNewOrder';
import EditSavedOrders from './SavedOrders';
import CreateSaved from './CreateSaved';

import { getOrders } from '../../components/getters';
import ru_RU from 'antd/lib/locale/ru_RU';
import { OrdersTable } from './OrdersTable';
import { changeDocsStatus, changeStatus } from './components/OrderStatus';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Spinner from '../../components/Supply/Spinner';
import RegistryModalFromOrders from './RegistryModalFromOrders';
const { RangePicker } = DatePicker;


const TextPlace = styled.div`
    max-width: 150px;
    max-height: 60px;
    overflow: auto;
`

const StatusWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const Orders = ({ socket, openNotification }) => {

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const [orders, setOrders] = useState('');
    const [filterData, setFilterData] = useState('');
    const [orderToEdit, setOrderToEdit] = useState();
    const [switchWithoutDate, setSwitchWithoutDate] = useState(false);
    const [dateForDateRange, setDateForDateRange] = useState(
        [dayjs().subtract(20, 'days'), dayjs().add(10, 'days')]
    )

    const [createRegistryModal, setCreateRegistryModal] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openSave, setOpenSave] = useState(false);
    const [openCreateSaved, setOpenCreateSaved] = useState(false);

    const [spinning, setSpinning] = useState(false);

    const [paginationData, setPaginationData] = useState({ page: 1, pageSize: 50 });
    const [totalPages, setTotalPages] = useState(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const setStatus = async (type, variant) => {
        for (const item of selectedRowKeys) {
            variant === 'status' ? await changeStatus(orders.find(el => el._id === item)._id, type) :
                await changeDocsStatus(item, type);
        }
        setSwitchWithoutDate(false)
        await gettingOrders(true);
        setSelectedRowKeys([]);
    }

    const roomName = `Orders ${userCompany}`;

    const gettingOrders = useCallback(async (isUpdate = false) => {
        let queryData = { status: { $ne: 'deleted' } }
        if (dateForDateRange[0] && dateForDateRange[1]) {
            queryData.date = {}
            Object.assign(queryData.date, { $gte: dayjs(dateForDateRange[0]).startOf('day'), $lte: dayjs(dateForDateRange[1]).endOf('day') })
        } else if (dateForDateRange[0]) {
            queryData.date = {}
            Object.assign(queryData.date, { $gte: dayjs(dateForDateRange[0]).startOf('day')})
        } else if (dateForDateRange[1]) {
            queryData.date = {}
            Object.assign(queryData.date, { $lte: dayjs(dateForDateRange[1]).endOf('day') })
        }

        const res = await getOrders(queryData, paginationData, filteredInfo ? filteredInfo : null);
        setOrders(res.orders.map(el => ({
            ...el,
            key: el._id,
            rowKey: el._id
        })));

        setFilterData(res.filters)

        setTotalPages(res.pagination)

        if (isUpdate) {
            socket.emit('message', {
                text: 'update',
                room: roomName,
                id: `${socket.id}${Math.random()}`,
                socketID: socket.id,
            });
        }
    }, [dateForDateRange, roomName, socket, paginationData, filteredInfo])

    const getSocketConnect = useCallback(async () => {
        socket.on('update', async (data) => {
            setSwitchWithoutDate(false)
            await gettingOrders(false);
        });
    }, [gettingOrders, socket])

    useEffect(() => {
        gettingOrders(false);
        socket.emit('enterRoom', roomName);
        getSocketConnect();
        document.title = "Заказы - ГрузДок - сервис учета грузоперевозок"
        return () => {
            socket.emit('leaveRoom', roomName);
        };

    }, [getSocketConnect, gettingOrders, roomName, socket])





    const handleCancel = () => {
        setOpen(false);
    }

    const handleCancelEdit = () => {
        setOpenEdit(false);
    }

    const deleteOrders = async () => {
        Modal.confirm({
            onOk: () => handleOkDeleteOrders(),
            title: 'Удалить',
            content: 'Подтвердите удаление',
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            ),
        })
    }

    const handleOkDeleteOrders = async () => {
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const currentOrder = orders.find(el => el._id === selectedRowKeys[i]);
            if (currentOrder.status === 'Created') {
                await ordersService.delete({ _id: selectedRowKeys[i] });
            } else {
                openNotification('Ошибка!', `Заказ №${currentOrder.order} используется в документах/реестре`, 'error')
            }
        }
        await gettingOrders(true);
        setSwitchWithoutDate(false)
        setSelectedRowKeys([]);
    }

    const showModalSaved = () => {
        setOpenSave(true)
    }

    const showModalCreateSaved = () => {
        setOpenCreateSaved(true)
    }

    const closeModalRegistry = () => {
        setCreateRegistryModal(false);
    }

    const closeModalCreateSaved = () => {
        setOpenCreateSaved(false);
    }


    const closeModalSaved = () => {
        setOpenSave(false)
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
            hidden: true,
        },
        // Table.EXPAND_COLUMN,
        {
            title: '№',
            dataIndex: 'order',
            key: 'order',
            width: 40,
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => text ? <TextPlace><b>{moment(text).format('DD/MM/YY')}</b></TextPlace> : null,
            width: 67,
        },
        {
            title: 'Время',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
            width: 50,
        },
        {
            title: 'Контрагент',
            dataIndex: 'agent',
            key: 'agent',
            filteredValue: filteredInfo.agent || null,
            filterSearch: true,
            filters: filterData ? filterData.agent : null,
            onFilter: (value, record) => record?.agent?._id === value,
            render: (text, row) => text?.shortName ? <TextPlace>{text.shortName}</TextPlace> : null,
            width: 150,
        },
        {
            title: 'Водитель',
            dataIndex: 'driver',
            key: 'driver',
            filteredValue: filteredInfo.driver || null,
            filterSearch: true,
            filters: filterData ? filterData.driver : null,
            onFilter: (value, record) => record?.driver?._id === value,
            render: (text, row) => text?.name ? <TextPlace>{text.name}</TextPlace> : null,
            width: 100,
        },
        {
            title: 'ГОС',
            dataIndex: 'number',
            key: 'number',
            filteredValue: filteredInfo.number || null,
            filterSearch: true,
            filters: filterData ? filterData.number : null,
            onFilter: (value, record) => record?.number?._id === value,
            render: (text, row) => text?.number ? <TextPlace>{text.number}</TextPlace> : null,
            width: 50,
        },
        {
            title: 'Док.',
            dataIndex: 'docStatus',
            key: 'docStatus',
            filteredValue: filteredInfo.docStatus || null,
            filterSearch: true,
            filters: filterData ? filterData.docStatus : null,
            onFilter: (value, record) => record?.docStatus === value,
            render: (text) =>
            (
                <StatusWrapper>
                    {
                        text === 'Attention' ? <Attention></Attention> :
                            text === 'Submit' ? <Submited></Submited> :
                                text === 'Office' ? <DocsOffice></DocsOffice> :
                                    null
                    }
                </StatusWrapper>
            ),
            width: 20,
        },
        {
            title: 'Оплата',
            dataIndex: 'company',
            key: 'company',
            filteredValue: filteredInfo.company || null,
            filterSearch: true,
            filters: filterData ? filterData.company : null,
            onFilter: (value, record) => record?.company?._id === value,
            render: (text, row) =>
            (
                <TextPlace>
                    {
                        row.status === 'Paid' ? <InvoicePaid>{text.shortName}</InvoicePaid> :
                            row.status === 'InvoiceIssued' ? <InvoiceIssued>{text.shortName}</InvoiceIssued> :
                                row.status === 'ReadyForInvoicing' ? <ReadyForInvoicing>{text.shortName}</ReadyForInvoicing> :
                                    text.shortName
                    }
                </TextPlace>
            ),
            width: 150,
        },
        {
            title: 'Ставка',
            dataIndex: 'bet',
            key: 'bet',
            width: 70,
            filters: filterData ? filterData.bet : null,
            filteredValue: filteredInfo.bet || null,
            onFilter: (value, record) => record.bet.startsWith(value),
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'т/м3/п',
            dataIndex: 'weight',
            key: 'weight',
            width: 60,
            filteredValue: filteredInfo.weight || null,
            filters: filterData ? filterData.weight : null,
            onFilter: (value, record) => record.weight.startsWith(value),
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'Загрузка',
            dataIndex: 'routes',
            key: 'routeA',
            render: (text, row) => <TextPlace>{text[0][0]}</TextPlace>,
            width: 150,
        },
        {
            title: 'Выгрузка',
            dataIndex: 'routes',
            key: 'routeB',
            render: (text, row) => <TextPlace>{text[text.length - 1][1]}</TextPlace>,
            width: 150,
        },
        {
            title: 'Часы',
            dataIndex: 'time',
            key: 'time',
            width: 40,
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'Ставка водителя',
            dataIndex: 'driver_bet',
            key: 'driver_bet',
            width: 40,
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'Комментарии',
            dataIndex: 'comment',
            key: 'comment',
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
            width: 100,
        },
        {
            title: <Tooltip title="Кругорейс"><ArrowClockwise /></Tooltip>,
            dataIndex: 'isRound',
            key: 'isRound',
            render: (text, row) => (
                text === true ? <Check style={{ 'color': 'green' }} /> :
                    <X style={{ 'color': 'red' }} />
            )
        },
        {
            title: <Tooltip title="Периодичный"><Infinity /></Tooltip>,
            dataIndex: 'isFrequency',
            key: 'isFrequency',
            render: (text, row) => (
                text === true ? <Check style={{ 'color': 'green' }} /> :
                    <X style={{ 'color': 'red' }} />
            )
        },
        {
            title: 'Сортировка',
            dataIndex: 'innerSort',
            key: 'innerSort',
            hidden: true,
        }
    ].filter(item => !item.hidden);


    const setDateRange = async (value) => {
        if (value) {
            setDateForDateRange(value)
            setPaginationData({ page: 1, pageSize: 50 })
            setSwitchWithoutDate(false)
            gettingOrders(false);
        } else {
            setDateForDateRange([])
            gettingOrders(false);
            
        }
    }

    const onChangeSwitch = (checked) => {
        if (checked) {
            setSwitchWithoutDate(true)
            gettingOrders(false, { date: { $exists: false } });
        } else {
            setSwitchWithoutDate(false)
            gettingOrders(false);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        setPaginationData({ page: pagination.current, pageSize: pagination.pageSize })
        setFilteredInfo(filters);
    };

    return (
        <>
            {
                spinning ?
                    <Spinner spinning={spinning} />
                    :
                    <>
                        <ButtonsFlex>
                            <ButtonsWrapper>

                            </ButtonsWrapper>
                            <ButtonsWrapper>
                                Без даты: <Switch checked={switchWithoutDate} onChange={onChangeSwitch} />
                                <ConfigProvider locale={ru_RU}>
                                    <RangePicker
                                        format={'DD-MM-YYYY'} onChange={setDateRange} defaultValue={dateForDateRange} allowClear={true}
                                    />
                                </ConfigProvider>
                            </ButtonsWrapper>
                            <ButtonsWrapper>
                                <Tooltip title="Документов нет">
                                    <IconButton onClick={() => setStatus('Nothing', 'docks')} style={{ 'color': '#000' }} icon={<XCircleFill />}></IconButton>
                                </Tooltip>
                                <Tooltip title="В офисе">
                                    <IconButton onClick={() => setStatus('Office', 'docks')} style={{ 'color': '#5f67c3' }} icon={<HouseFill />}></IconButton>
                                </Tooltip>
                                <Tooltip title="Документы сданы">
                                    <IconButton onClick={() => setStatus('Submit', 'docks')} style={{ 'color': '#9cb996' }} icon={<Check2Circle />}></IconButton>
                                </Tooltip>
                                <Tooltip title="Обратить внимание">
                                    <IconButton onClick={() => setStatus('Attention', 'docks')} style={{ 'color': '#cd304c' }} icon={<ExclamationCircleFill />}></IconButton>
                                </Tooltip>
                            </ButtonsWrapper>
                        </ButtonsFlex>
                        {
                            orders &&
                            <>
                                <OrdersTable
                                    columns={columns}
                                    data={orders}
                                    setOpen={setOpen}
                                    showModalSaved={showModalSaved}
                                    showModalCreateSaved={showModalCreateSaved}
                                    setOrderToEdit={setOrderToEdit}
                                    setOpenEdit={setOpenEdit}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    selectedRowKeys={selectedRowKeys}
                                    deleteOrders={deleteOrders}
                                    handleChange={handleChange}
                                    filteredInfo={filteredInfo}
                                    setCreateRegistryModal={setCreateRegistryModal}
                                    totalPages={totalPages}
                                    userCompany={userCompany}
                                    openNotification={openNotification}
                                    gettingOrders={gettingOrders}
                                />

                                <Modal
                                    title="Новый заказ"
                                    open={open}
                                    onCancel={handleCancel}
                                    footer={null}
                                    destroyOnClose={true}
                                    width={850}
                                    style={{ top: 10 }}
                                    afterClose={gettingOrders}
                                >
                                    <AddNewOrder setOpen={setOpen} userCompany={userCompany} openNotification={openNotification} />
                                </Modal>

                                <Modal
                                    title="Редактирование заказа"
                                    open={openEdit}
                                    onCancel={handleCancelEdit}
                                    footer={null}
                                    // destroyOnClose={true}
                                    width={850}
                                    style={{ top: 10 }}
                                    afterClose={gettingOrders}
                                >
                                    <AddNewOrder setOpen={setOpenEdit} userCompany={userCompany} orderToEdit={orderToEdit} openNotification={openNotification} />
                                </Modal>

                                <Modal
                                    title="Сохраненные маршруты"
                                    open={openSave}
                                    destroyOnClose={true}
                                    onCancel={closeModalSaved}
                                    footer={null}
                                    width='100%'
                                    style={{ top: 10 }}
                                    afterClose={gettingOrders}
                                >
                                    <EditSavedOrders closeModal={closeModalSaved} userCompany={userCompany} openNotification={openNotification} />
                                </Modal>

                                <Modal
                                    title="Загрузка маршрутов"
                                    open={openCreateSaved}
                                    onCancel={closeModalCreateSaved}
                                    footer={null}
                                    destroyOnClose={true}
                                    width={350}
                                    style={{ top: 10 }}
                                    afterClose={gettingOrders}
                                >
                                    <CreateSaved userCompany={userCompany} setOpenCreateSaved={setOpenCreateSaved} closeModalCreateSaved={closeModalCreateSaved} />
                                </Modal>


                                <Modal
                                    title="Параметры"
                                    open={createRegistryModal}
                                    onCancel={closeModalRegistry}
                                    footer={null}
                                    destroyOnClose={true}
                                    width={550}
                                    style={{ top: 10 }}
                                    afterClose={gettingOrders}
                                >
                                    <RegistryModalFromOrders socket={socket} gettingOrders={gettingOrders} closeModalRegistry={closeModalRegistry} openNotification={openNotification} />
                                </Modal>
                            </>
                        }
                    </>
            }

        </>
    );
};

export default Orders;