import React, { useEffect, useState } from 'react';
import OrdersService from '../../services/OrdersService';
import styled from 'styled-components';
import { ConfigProvider, Space, Table, Tooltip } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import Spinner from '../../components/Supply/Spinner';
import { InvoicePaid, InvoiceIssued, ReadyForInvoicing, IconButton } from '../Orders/components/styles';
import moment from 'moment';
import { removeDuplicates } from '../../components/getters';

import { Infinity, Check, X, ArrowClockwise } from 'react-bootstrap-icons';

const TableWrapper = styled.div`
    overflow: auto;
    font-family: 'Roboto', sans-serif;
`

const TextPlace = styled.div`
    max-width: 150px;
    max-height: 60px;
    overflow: auto;
`

const BucketOrders = ({ openNotification, userCompany }) => {

    const [orders, setOrders] = useState();
    const [spinning, setSpinning] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(50)

    useEffect(() => {
        getDeletedOrders()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: '_id', //simple accessorKey pointing to flat data
            key: '_id',
            hidden: true,
        },
        {
            title: '№',
            dataIndex: 'order',
            key: 'order',
            width: 40,
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => text ? <TextPlace><b>{moment(text).format('DD/MM/YY')}</b></TextPlace> : null,
            width: 67,
        },
        {
            title: 'Время удаления',
            dataIndex: 'timeStamp',
            key: 'timeStamp',
            render: (text) => text ? <TextPlace><b>{moment(text).format('DD/MM/YY H:mm')}</b></TextPlace> : null,
            width: 67,
        },
        {
            title: 'Время',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
            width: 50,
        },
        {
            title: 'Контрагент',
            dataIndex: 'agent',
            key: 'agent',
            filteredValue: filteredInfo.agent || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.agent.shortName, value: el.agent._id }))) : null,
            onFilter: (value, record) => record.agent._id.startsWith(value),
            render: (text, row) => text?.shortName ? <TextPlace>{text.shortName}</TextPlace> : null,
            width: 150,

        },
        {
            title: 'Водитель',
            dataIndex: 'driver',
            key: 'driver',
            filteredValue: filteredInfo.driver || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.driver?.name, value: el.driver?.name }))) : null,
            onFilter: (value, record) => {
                const result = record.driver ? record.driver.name.startsWith(value) : null;
                return result;
            },
            render: (text, row) => text?.name ? <TextPlace>{text.name}</TextPlace> : null,
            width: 100,
        },
        {
            title: 'ГОС',
            dataIndex: 'number',
            key: 'number',
            filteredValue: filteredInfo.number || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.number?.number, value: el.number?._id }))) : null,
            onFilter: (value, record) => record.number._id.startsWith(value),
            render: (text, row) => text?.number ? <TextPlace>{text.number}</TextPlace> : null,
            width: 50,
        },
        {
            title: 'Оплата',
            dataIndex: 'company',
            key: 'company',
            filteredValue: filteredInfo.company || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.company?.shortName, value: el.company?._id }))) : null,
            onFilter: (value, record) => record.company._id.startsWith(value),
            render: (text, row) =>
            (
                <TextPlace>
                    {
                        row.status === 'Paid' ? <InvoicePaid>{text.shortName}</InvoicePaid> :
                            row.status === 'InvoiceIssued' ? <InvoiceIssued>{text.shortName}</InvoiceIssued> :
                                row.status === 'ReadyForInvoicing' ? <ReadyForInvoicing>{text.shortName}</ReadyForInvoicing> :
                                    text.shortName
                    }
                </TextPlace>
            ),
            width: 150,
        },
        {
            title: 'Ставка',
            dataIndex: 'bet',
            key: 'bet',
            width: 70,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.bet, value: el.bet }))) : null,
            filteredValue: filteredInfo.bet || null,
            onFilter: (value, record) => record.bet.startsWith(value),
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'т/м3/п',
            dataIndex: 'weight',
            key: 'weight',
            width: 60,
            filteredValue: filteredInfo.weight || null,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.weight, value: el.weight }))) : null,
            onFilter: (value, record) => record.weight.startsWith(value),
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'Загрузка',
            dataIndex: 'routes',
            key: 'routeA',
            render: (text, row) => <TextPlace>{text[0][0]}</TextPlace>,
            width: 150,
        },
        {
            title: 'Выгрузка',
            dataIndex: 'routes',
            key: 'routeB',
            render: (text, row) => <TextPlace>{text[text.length - 1][1]}</TextPlace>,
            width: 150,
        },
        {
            title: 'Часы',
            dataIndex: 'time',
            key: 'time',
            width: 40,
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'Ставка водителя',
            dataIndex: 'driver_bet',
            key: 'driver_bet',
            width: 40,
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'Комментарии',
            dataIndex: 'comment',
            key: 'comment',
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
            width: 100,
        },
        {
            title: <Tooltip title="Кругорейс"><ArrowClockwise /></Tooltip>,
            dataIndex: 'isRound', //simple accessorKey pointing to flat data
            key: 'isRound',
            render: (text, row) => (
                text === true ? <Check style={{ 'color': 'green' }} /> :
                    <X style={{ 'color': 'red' }} />
            )

        },
        {
            title: <Tooltip title="Периодичный"><Infinity /></Tooltip>,
            dataIndex: 'isFrequency', //simple accessorKey pointing to flat data
            key: 'isFrequency',
            render: (text, row) => (
                text === true ? <Check style={{ 'color': 'green' }} /> :
                    <X style={{ 'color': 'red' }} />
            )

        },
        {
            title: 'Сортировка',
            dataIndex: 'innerSort', //simple accessorKey pointing to flat data
            key: 'innerSort',
            hidden: true,
        },
        {
            title: 'Действия',
            dataIndex: 'operation',
            render: (_, record) => (
                <Space>
                    <Tooltip title="Восстановить">
                        <IconButton onClick={() => createAgain(record._id)} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                            icon={
                                <ArrowClockwise style={{ 'color': 'green', 'width': '12px', 'height': '12px' }} />
                            } />
                    </Tooltip>
                </Space>
            ),
        },
    ].filter(item => !item.hidden);

    const getDeletedOrders = async () => {
        setSpinning(true);
        const getOrders = await OrdersService.getOrdersForPayment({ status: 'deleted', company_id: userCompany });
        console.log(getOrders)
        setOrders(getOrders.data.map(el => ({...el, key: el._id})))
        setSpinning(false);
    }

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const createAgain = async (data) => {
        await OrdersService.changeStatus(data,'Created');
        openNotification('Успешно!', 'Заказ восстановлен!', 'info')
        getDeletedOrders();
    }

    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row-table' : 'odd-row-table';
    };

    const onChangePage = (currentPage, currentPageSize) => {
        setCurrentPage(currentPage)
        setCurrentPageSize(currentPageSize)
    }

    return (
        <TableWrapper>
            {
                spinning ?
                    <Spinner spinning={spinning} />
                    :
                    <ConfigProvider locale={ru_RU}>
                        <Table
                            dataSource={orders}
                            columns={columns}
                            size="small"
                            bordered={true}
                            rowClassName={rowClassName}
                            pagination={{
                                onChange: onChangePage,
                                current: currentPage,
                                pageSize: currentPageSize,
                                pageSizeOptions: [50, 100, 150],
                                position: ['topRight', 'bottomRight']
                            }}
                            handleChange={handleChange}
                        />


                    </ConfigProvider>
            }

        </TableWrapper >
    );
};

export default BucketOrders;