import React, { useState } from 'react';
import OrdersService from '../../services/OrdersService';
import dayjs from 'dayjs';
import { DatePicker, ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import styled from 'styled-components';

const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
]

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
`

const ButtonDefaultStyle = styled.button`
    display: flex;
    align-items: center;
    max-height: 40px;
    padding: 8px 16px;
    border: none;
    color: #fff;
    max-width: 150px;
`

const ButtonPrimary = styled(ButtonDefaultStyle)`
    background-color: #001529;
    transition: 0.5s all;
    &:hover{
        outline: 1px solid #001529;
        background-color: #fff; 
        color: #001529;
    }
`

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

const CreateSaved = ({ closeModalCreateSaved, userCompany }) => {

    const [day, setDate] = useState('')

    const getRoutes = async () => {
        if (day) {
            const res = await OrdersService.getSavedOrders({ day: days[day.$W], company: userCompany });
            res.data.sort((a, b) => {
                const firstTime = a.startTime.split(':')
                const secTime = b.startTime.split(':')
                if (+firstTime[0] > +secTime[0]) {
                    return 1;
                }
                if (+firstTime[0] < +secTime[0]) {
                    return -1;
                }

                if (+firstTime[0] === +secTime[0]) {

                    if (+firstTime[1] < +secTime[1]) {
                        return 1;
                    }

                    if (+firstTime[1] > +secTime[1]) {
                        return -1;
                    }

                    if (+firstTime[1] === +secTime[1]) {
                        return 0;
                    }
                }
                return 0;
            })
            for (let i = 0; i < res.data.length; i++) {
                console.log(res.data[i])
                await myAsyncFunction(res.data[i]);
            }
            closeModalCreateSaved();
        }
    }

    const myAsyncFunction = async (value) => {
        delete value._id;
        await OrdersService.createOrEdit({
            ...value,
            date: day.$d,
            type: '1'
        });
    }

    const onChange = (date) => {
        if (date) {
            setDate(date)
        } else {
            setDate('')
        }
    };


    return (
        <div>
            <Wrapper>
                <ConfigProvider locale={ru_RU}>
                    <DatePicker
                        format={dateFormatList}
                        presets={[
                            {
                                label: 'Сегодня',
                                value: dayjs().add(0, 'd'),
                            },
                            {
                                label: 'Завтра',
                                value: dayjs().add(+1, 'd'),
                            },
                        ]}
                        onChange={onChange}
                    />
                </ConfigProvider>
                <ButtonPrimary onClick={getRoutes}>Добавить</ButtonPrimary>
            </Wrapper>

        </div>
    );
};

export default CreateSaved;