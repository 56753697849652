import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import CompaniesService from '../../services/CompaniesService';
import TextArea from 'antd/lib/input/TextArea';
import AgentsService from '../../services/AgentsService';
import PhoneMaskInput from '../../components/MaskInput/PhoneMaskInput';
import DateMaskInput from '../../components/MaskInput/DateMaskInput';


const AddPhysicalAgent = ({ setOpen, userCompany }) => {

    const [companies, setCompanies] = useState();

    useEffect(() => {
        getCompanies();
    }, [])

    const getCompanies = async () => {
        const res = await CompaniesService.get({ company_id: userCompany })
        setCompanies(res.data)
    }


    const onFinish = async (values) => {
        await AgentsService.createOrEdit({ ...values, company_id: userCompany, type: 'physics', shortName: `${values.name} ${values.secondName} ${values.surName}` });
        setOpen(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div>
            {
                companies &&

                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 30,
                    }}
                    labelAlign='left'
                    style={{
                        width: 700,
                        marginTop: '30px'
                    }}
                    layout='horizontal'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Имя"
                        name="name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Телефон"
                        name="phone"
                    >
                        <PhoneMaskInput />
                    </Form.Item>

                    <Form.Item
                        label="Дата рождения"
                        name="birth"
                    >
                        <DateMaskInput />
                    </Form.Item>

                    <Form.Item
                        label="Номер паспорта"
                        name="passNumber"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Дата выдачи"
                        name="passDate"
                    >
                        <DateMaskInput />
                    </Form.Item>
                    <Form.Item
                        label="Кем выдан"
                        name="passWho"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Прописка"
                        name="adress"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Вид оплаты"
                        name="company"
                    >
                        <Select
                            showSearch
                            placeholder="Вид оплаты"
                            optionFilterProp="children"
                            allowClear
                            options={companies.map(el => ({ value: el._id, label: el.shortName }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Комментарий"
                        name="comment"
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 20,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form>
            }

        </div>
    );
};

export default AddPhysicalAgent;