import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ButtonsWrapper } from '../../styles/buttons';
import { X, ChevronCompactUp, ChevronCompactDown, PlusLg } from 'react-bootstrap-icons';
import { Modal, Space, Table, Tooltip, Button } from 'antd';

import ordersService from '../../services/OrdersService';
import EditSavedOrder from './EditSavedOrder';
import { removeDuplicates } from '../../components/getters';
import styled from 'styled-components';
import ChooseDate from './ChooseDate';


const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const TextPlace = styled.div`
    max-width: 150px;
    max-height: 60px;
    overflow: auto;
`


const EditSavedOrders = ({ userCompany, openNotification }) => {

    const [open, setOpen] = useState(false);
    const [orders, setOrders] = useState();
    const [filteredInfo, setFilteredInfo] = useState({});
    const [orderToEdit, setOrderToEdit] = useState();
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(15)
    const [modalDate, setOpenModalDate] = useState(false);
    const createAgainData = useRef([])

    const getSavedOrders = useCallback(async () => {
        const res = await ordersService.getSavedOrders({ company_id: userCompany });
        const result = res.data.map(el => {
            const freq = el.isFrequency ? el.freqDays.map(el =>
                el === 'Monday' ? 'Пн' :
                    el === 'Tuesday' ? 'Вт' :
                        el === 'Wednesday' ? 'Ср' :
                            el === 'Thursday' ? 'Чт' :
                                el === 'Friday' ? 'Пт' :
                                    el === 'Saturday' ? 'Сб' :
                                        el === 'Sunday' ? 'Вс' :
                                            null
            ) : null
            return {
                ...el,
                project: el.project?.name ? el.project.name : 'Без проекта',
                freqDays: freq ? freq.join(', ') : null,
                key: el._id,
            }
        })
        setOrders(result);
    }, [userCompany])

    useEffect(() => {
        getSavedOrders();
    }, [getSavedOrders])

    const showModalSaved = (data) => {
        setOrderToEdit(data)
        setOpen(true)
    }

    const closeModalmodalDate = () => {
        setOpenModalDate(false);
    }

    const onChangePage = (currentPage, currentPageSize) => {
        setCurrentPage(currentPage)
        setCurrentPageSize(currentPageSize)
    }

    // const closeCreateSave = () => {
    //     setOpenCreateSave(false)
    // }

    const closeModalSaved = () => {
        setOpen(false)
    }

    const deleteSavedOrder = async (data) => {
        await ordersService.deleteSavedOrder({ _id: data });
        await getSavedOrders();
    }

    const createOrder = async (data) => {
        let order = await ordersService.getSavedOrders({ _id: data });
        order = order.data[0];
        delete order._id;
        createAgainData.current = order;
        setOpenModalDate(true)
    }


    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const columns = [
        Table.EXPAND_COLUMN,
        {
            title: 'Контрагент',
            dataIndex: 'agent',
            key: 'agent',
            filteredValue: filteredInfo.agent || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.agent.shortName, value: el.agent._id }))) : null,
            onFilter: (value, record) => record.agent._id.startsWith(value),
            render: (text, row) => <TextPlace>{text.shortName}</TextPlace>,
            width: 150,
        },
        {
            title: 'Оплата',
            dataIndex: 'company',
            key: 'company',
            filteredValue: filteredInfo.company || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.company?.shortName, value: el.company?._id }))) : null,
            onFilter: (value, record) => record.company._id.startsWith(value),
            render: (text, row) =>
            (
                <TextPlace>
                    {text.shortName}
                </TextPlace>
            ),
            width: 150,
        },
        {

            title: 'Ставка',
            dataIndex: 'bet',
            key: 'bet',
            width: 40,
        },
        {
            title: 'Время',
            dataIndex: 'startTime',
            key: 'startTime',
            width: 40,
        },
        {
            title: 'т/м3/п',
            dataIndex: 'weight',
            key: 'weight',
            width: 40,

        },
        {
            title: 'Загрузка',
            dataIndex: 'routes',
            key: 'routeA',
            render: (text, row) => <TextPlace>{text[0][0]}</TextPlace>,
            width: 150,
        },
        {
            title: 'Выгрузка',
            dataIndex: 'routes',
            key: 'routeB',
            render: (text, row) => <TextPlace>{text[text.length - 1][1]}</TextPlace>,
            width: 150,
        },
        {
            title: 'Часы',
            dataIndex: 'startTime',
            key: 'startTime',
            width: 40,
        },
        {
            title: 'Проект',
            dataIndex: 'project',
            key: 'project',
            width: 40,
        },
        {
            title: 'Комментарии',
            dataIndex: 'comment',
            key: 'comment',
            width: 40,
        },
        {
            title: 'Периодичность',
            dataIndex: 'freqDays',
            key: 'freqDays',
            width: 40,
        },
        {
            title: 'Действия',
            dataIndex: 'operation',
            width: 40,
            render: (_, record) => (
                <Space>
                    <Tooltip title="Добавить">
                        <IconButton onClick={() => createOrder(record._id)} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                            icon={
                                <PlusLg style={{ 'color': 'green', 'width': '12px', 'height': '12px' }} />
                            } />
                    </Tooltip>
                    <Tooltip title="Удалить">
                        <IconButton onClick={() => deleteSavedOrder(record._id)} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                            icon={
                                <X style={{ 'color': 'red', 'width': '12px', 'height': '12px' }} />
                            } />
                    </Tooltip>
                </Space>
            ),
        },
    ]

    const expandIcon = ({ expanded, onExpand, record }) => {
        if (record.routes) {
            return expanded ? (
                <ChevronCompactUp style={{ width: '18px', height: '18px' }} onClick={e => onExpand(record, e)} />
            ) : (
                <ChevronCompactDown style={{ width: '18px', height: '18px' }} onClick={e => onExpand(record, e)} />
            )
        } else {
            return null;
        }

    }

    const expandedRowRender = (record) => {
        const tableArr = record.routes;
        const arrayFromHtmlCollection = Array.from(document.getElementsByClassName('ant-table-row')[0].getElementsByTagName('td'));
        let firstTwelveElements = arrayFromHtmlCollection.slice(1, 7);
        let sumWidth = firstTwelveElements.reduce((acc, current) => {
            let elementWidth = current.clientWidth; // получаем ширину элемента
            return acc + elementWidth;
        }, 0);

        const columns = [
            {
                title: 'Загрузка',
                dataIndex: 'space',
                key: 'space',
                width: sumWidth,
            },
            {
                title: 'Загрузка',
                dataIndex: 'routes',
                render: (text, row) => row[0],
                key: 'routeA',
                width: '213.4px',
            },
            {
                title: 'Выгрузка',
                dataIndex: 'routes',
                render: (text, row) => row[1],
                key: 'routeB',
                width: '213.4px',
            },
        ];
        return <Table columns={columns} dataSource={tableArr} pagination={false} showHeader={false} style={{ display: 'flex' }} />;
    };

    return (
        <div>
            <ButtonsWrapper>
                {/* <ButtonPrimary onClick={() => setOpenCreateSave(true)}>Добавить</ButtonPrimary> */}
            </ButtonsWrapper>
            {
                orders ?
                    <Table
                        columns={columns}
                        dataSource={orders}
                        bordered
                        expandable={{
                            expandedRowRender,
                            expandIcon,
                        }}
                        onChange={handleChange}
                        onRow={(record) => {
                            return {
                                onDoubleClick: () => {
                                    showModalSaved(record);
                                },
                            };
                        }}
                    /> : null
            }


            <Modal
                title="Редактирование"
                open={open}
                onCancel={closeModalSaved}
                footer={null}
                width='100%'
                style={{ top: 10 }}
                destroyOnClose={true}
                afterClose={getSavedOrders}
                pagination={{
                    onChange: onChangePage,
                    current: currentPage,
                    pageSize: currentPageSize,
                    pageSizeOptions: [15, 30, 45],
                    position: ['topRight', 'bottomRight']
                }}
            >
                <EditSavedOrder orderToEdit={orderToEdit} setOpen={setOpen} />
            </Modal>

            <Modal
                title="Выберите дату"
                open={modalDate}
                onCancel={closeModalmodalDate}
                footer={null}
                destroyOnClose={true}
                width={350}
                style={{ top: 10 }}
            >
                <ChooseDate data={createAgainData.current} closeModalmodalDate={closeModalmodalDate} openNotification={openNotification}/>
            </Modal>

            {/* <Modal
                title="Создание сохраненного маршрута"
                open={openCreateSave}
                onCancel={closeCreateSave}
                footer={null}
                width='450px'
                style={{ top: 10 }}
                destroyOnClose={true}
            >
                <CreateSaveOrder closeCreateSave={closeCreateSave} getSavedOrders={getSavedOrders} userCompany={userCompany} />
            </Modal> */}
        </div>
    );
};

export default EditSavedOrders;