import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import agentsService from '../../services/AgentsService';
import CompaniesService from '../../services/CompaniesService';
import styled from 'styled-components';
import { Checkbox, Form, Input, Select, Skeleton, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { DashCircleDotted, PlusCircleDotted } from 'react-bootstrap-icons';
import { Button } from 'antd/lib';
import OrdersService from '../../services/OrdersService';
import TimeMaskInput from '../../components/MaskInput/TimeMaskInput';


const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`

const EditSavedOrder = ({ orderToEdit, setOpen }) => {

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);
    const allData = useRef({});
    const [allDataLoaded, setAllDataLoaded] = useState(false);
    const [formEdit] = Form.useForm();

    const getData = useCallback(async () => {
        const companies = await CompaniesService.get({ company_id: userCompany });
        allData.company = companies.data;

        const agents = await agentsService.get({ company_id: userCompany });
        allData.agents = agents.data;
        setAllDataLoaded(true);
    }, [userCompany])

    useEffect(() => {
        getData()
    }, [getData])


    let setFreqDays;
    if (allDataLoaded) {
        
        if (orderToEdit?.freqDays) {
            setFreqDays = orderToEdit.freqDays.split(', ')
            setFreqDays = setFreqDays.map(el => {
                if (el === 'Пн') return 'Monday';
                if (el === 'Вт') return 'Tuesday';
                if (el === 'Ср') return 'Wednesday';
                if (el === 'Чт') return 'Thursday';
                if (el === 'Пт') return 'Friday';
                if (el === 'Сб') return 'Saturday';
                if (el === 'Вс') return 'Sunday';
                return null;
            })
        }



        formEdit.setFieldsValue({
            ...orderToEdit,
            isRound: orderToEdit?.isRound ? orderToEdit.isRound : false,
            comment: orderToEdit?.comment ? orderToEdit.comment : '',
            freqDays: setFreqDays,
            isFrequency: orderToEdit?.isFrequency ? orderToEdit.isFrequency : false,
            company: orderToEdit.company?._id ? allData.company.find(el => el._id === orderToEdit.company._id)._id : null,
            agent: orderToEdit.agent?._id ? allData.agents.find(el => el._id === orderToEdit.agent._id)._id : null,
            routes: orderToEdit.routes.map(el => ({ a: el[0], b: el[1] }))
        })
    }

    const onFinish = async (values) => {
        values.routes = values.routes.map(el => Object.values(el))
        console.log(values)
        await OrdersService.editSaveOrder({ ...values, company_id: userCompany });
        setOpen(false)
    };

    const onFinishFailed = (errorInfo) => {
        // openNotification('Ошибка!', errorInfo.errorFields[0].errors[0], 'error')
    };

    const agentHandle = async (value) => {
        const fieldAgent = allData.agents.find(el => el._id === value);
        const searchCompany = allData.company.find(el => el._id === fieldAgent.company);
        // const projects = await ProjectService.get({ company_id: userCompany });
        formEdit.setFieldsValue({
            company: { value: searchCompany._id, label: searchCompany.shortName }
        })
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Wrapper>
            {
                allDataLoaded ?
                    <Form
                        form={formEdit}
                        name="CreateForm"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 22,
                        }}
                        labelAlign='left'
                        labelWrap={true}
                        style={{
                            width: 800,
                        }}
                        layout='horizontal'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Время начала"
                            name="startTime"
                            wrapperCol={{
                                span: 5,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Обязательно к заполнению',
                                },
                            ]}
                        >
                            <TimeMaskInput />
                        </Form.Item>
                        <Form.Item
                            label="Загрузка"
                            name="agent"
                            rules={[
                                {
                                    required: true,
                                    message: 'Загрузка обязательно к заполнению',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Выбрать контрагента"
                                optionFilterProp="children"
                                onChange={agentHandle}
                                allowClear={orderToEdit ? false : true}
                                filterOption={filterOption}
                                options={allData.agents && allData.agents.map(el => ({ value: el._id, label: el.type === 'official' ? el.shortName : `${el.name} ${el.secondName} ${el.surName}` }))}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Контрагент"
                            name="company"
                        >
                            <Select
                                showSearch
                                placeholder="Контрагент"
                                allowClear
                                optionFilterProp="children"
                                options={allData.company && allData.company.map(el => ({ value: el._id, label: el.shortName }))}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Ставка"
                            name="bet"
                        >
                            <Input type='number' allowClear />
                        </Form.Item>

                        <Form.Item
                            label="Ставка водителя"
                            name="driver_bet"
                        >
                            <Input type='number' allowClear />
                        </Form.Item>

                        <Form.Item
                            label="т/м3/п"
                            name="weight"
                        >
                            <Input allowClear />
                        </Form.Item>

                        <Space
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between'
                            }}
                            align="start">
                            Маршрут:
                            <Form.List name="routes"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Маршрут обязателен к заполнению!',
                                    },
                                ]}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }, index) => (
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    width: '100%'
                                                }}
                                                align="start"
                                            >

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'a']}
                                                    style={{
                                                        width: '300px',
                                                    }}
                                                    initialValue=''
                                                >
                                                    <TextArea placeholder={`Загрузка ${index + 1}`} />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'b']}
                                                    style={{
                                                        width: '300px',
                                                    }}
                                                    initialValue=''
                                                >
                                                    <TextArea placeholder={`Выгрузка ${index + 1}`} />
                                                </Form.Item>
                                                <DashCircleDotted onClick={() => {
                                                    remove(name)
                                                }} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button style={{ 'width': '600px' }} type="dashed" onClick={() => add()} block icon={<PlusCircleDotted />}>
                                                Добавить
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Space>
                        <Form.Item
                            label="Кругорейс"
                            name="isRound"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>

                        <Form.Item
                            label="Периодичный"
                            name="isFrequency"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>

                        <Form.Item
                            label="Дни (не важно при выключенной периодичности)"
                            name="freqDays"
                        >
                            <Select
                                showSearch
                                mode="multiple"
                                allowClear
                                placeholder="Выберите дни"
                                optionFilterProp="children"
                                options={[
                                    { value: 'Monday', label: 'Понедельник' },
                                    { value: 'Tuesday', label: 'Вторник' },
                                    { value: 'Wednesday', label: 'Среда' },
                                    { value: 'Thursday', label: 'Четверг' },
                                    { value: 'Friday', label: 'Пятница' },
                                    { value: 'Saturday', label: 'Суббота' },
                                    { value: 'Sunday', label: 'Воскресенье' }
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Комментарии"
                            name="comment"
                        >
                            <TextArea rows={4} allowClear />
                        </Form.Item>

                        <Form.Item
                            label="ID"
                            name="_id"
                            hidden
                        >
                            <TextArea rows={4} allowClear />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 20,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form> : <Skeleton />
            }

        </Wrapper>
    );
};

export default EditSavedOrder;