import React, { useRef, useState } from 'react';
import { ArrowClockwise, ChevronCompactDown, ChevronCompactUp, DatabaseAdd, Download, FileEarmarkXFill, FolderPlus, Receipt, TruckFrontFill } from 'react-bootstrap-icons';
import { Tooltip, Table, ConfigProvider, Modal } from 'antd';
import styled from 'styled-components';
import { IconButton, } from './components/styles';
import ru_RU from 'antd/lib/locale/ru_RU';
import SaveRouteModal from './SaveRouteModal';
import ChooseDate from './ChooseDate';


const ButtonsWrapper = styled.div`
    display: flex;
    justify-conent: start;
    gap: 16px;
`

const TableWrapper = styled.div`
    overflow: auto;
    font-family: 'Roboto', sans-serif;

`

const TopPanel = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    padding: 0 18px;
`

export const OrdersTable = ({ columns, data, selectedRowKeys, setSelectedRowKeys, setOpen, createRegistry, showModalSaved, setOpenEdit, setOrderToEdit, showModalCreateSaved, deleteOrders, handleChange, filteredInfo, setCreateRegistryModal, totalPages, userCompany, openNotification, gettingOrders }) => {

    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(50)
    const [saveRoute, setSaveRoute] = useState('');
    const [modalDate, setOpenModalDate] = useState(false);
    const [openSaveRoute, setOpenSaveRoute] = useState(false);
    const createAgainData = useRef([])
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const closeModalmodalDate = () => {
        setOpenModalDate(false);
    }

    const addSaveRoute = (element) => {
        if (element.length > 1) {
            openNotification('Ошибка!', 'Выбрано больше одного маршрута', 'error')
            return;
        } else if (element.length === 0) {
            openNotification('Ошибка!', 'Не выбран маршрут', 'error')
            return;
        } else {
            setSaveRoute(data.find(el => el._id === element[0]))
            setOpenSaveRoute(true)
        }
    }

    const closeModalSaveRoute = () => {
        setOpenSaveRoute(false);
    }

    const createAgain = async (element) => {
        if (element.length > 1) {
            openNotification('Ошибка!', 'Выбрано больше одного маршрута', 'error')
            return;
        } else if (element.length === 0) {
            openNotification('Ошибка!', 'Не выбран маршрут', 'error')
            return;
        } else {
            const order = data.find(el => el._id === element[0]);
            const create = {
                agent: order.agent._id,
                company: order.company,
                bet: order.bet,
                weight: order.weight,
                routes: order.routes,
                time: order.time,
                company_id: order.company_id,
                isRound: order.isRound,
                startTime: order.startTime,
            }
            createAgainData.current = create;
            setOpenModalDate(true);
        }
    }

    const Header = () => (
        <>
            <TopPanel>
                <ButtonsWrapper>
                    <Tooltip title="Добавить заказ">
                        <IconButton onClick={() => {
                            setOpen(true)
                        }} style={{ 'color': '#fff', 'backgroundColor': '#9cb996' }} icon={<DatabaseAdd />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить">
                        <IconButton onClick={() => deleteOrders()
                        } style={{ 'color': '#ff5319' }} icon={<FileEarmarkXFill />}></IconButton>
                    </Tooltip>
                </ButtonsWrapper >

                <ButtonsWrapper>
                    <Tooltip title="Сформировать реестр">
                        <IconButton onClick={() => setCreateRegistryModal(true)} style={{ 'color': '#001529' }} icon={<Receipt />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Сохраненные маршруты">
                        <IconButton onClick={showModalSaved} style={{ 'color': '#001529' }} icon={<FolderPlus />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Запланировать сохраненные маршруты">
                        <IconButton onClick={showModalCreateSaved} style={{ 'color': '#001529' }} icon={<TruckFrontFill />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Повторить маршрут">
                        <IconButton onClick={() => createAgain(selectedRowKeys)} style={{ 'color': '#001529' }} icon={<ArrowClockwise />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Сохранить маршрут">
                        <IconButton onClick={() => addSaveRoute(selectedRowKeys)} style={{ 'color': '#001529' }} icon={<Download />}></IconButton>
                    </Tooltip>
                </ButtonsWrapper >
            </TopPanel>
        </>

    )

    const DataTable = () => {
        const rowClassName = (record, index) => {
            return index % 2 === 0 ? 'even-row-table' : 'odd-row-table';
        };

        // const expandedRowRender = (record) => {
        //     const tableArr = record.routes;
        //     const arrayFromHtmlCollection = Array.from(document.getElementsByClassName('ant-table-row')[0].getElementsByTagName('td'));
        //     let firstTwelveElements = arrayFromHtmlCollection.slice(1, 12);
        //     let sumWidth = firstTwelveElements.reduce((acc, current) => {
        //         let elementWidth = current.clientWidth; // получаем ширину элемента
        //         return acc + elementWidth;
        //     }, 0);

        //     const columns = [
        //         {
        //             title: 'Загрузка',
        //             dataIndex: 'space',
        //             key: 'space',
        //             width: sumWidth,
        //         },
        //         {
        //             title: 'Загрузка',
        //             dataIndex: 'routes',
        //             render: (text, row) => row[0],
        //             key: 'routeA',
        //             width: '99.45px',
        //         },
        //         {
        //             title: 'Выгрузка',
        //             dataIndex: 'routes',
        //             render: (text, row) => row[1],
        //             key: 'routeB',
        //             width: '99.45px',
        //         },
        //     ];
        //     return <Table rowClassName={rowClassName} columns={columns} dataSource={tableArr} pagination={false} showHeader={false} style={{ display: 'flex' }} />;
        // };

        const expandIcon = ({ expanded, onExpand, record }) => {
            if (record.routes.length > 1) {
                return expanded ? (
                    <ChevronCompactUp style={{ width: '18px', height: '18px' }} onClick={e => onExpand(record, e)} />
                ) : (
                    <ChevronCompactDown style={{ width: '18px', height: '18px' }} onClick={e => onExpand(record, e)} />
                )
            } else {
                return null;
            }

        }

        const onChangePage = (currentPage, currentPageSize) => {
            setCurrentPage(currentPage)
            setCurrentPageSize(currentPageSize)
        }

        return (
            <>
                <TableWrapper>
                    <Header />
                    <ConfigProvider locale={ru_RU}>
                        <Table
                            rowSelection={rowSelection}
                            dataSource={data}
                            rowKey="_id"
                            columns={columns}
                            size="small"
                            bordered={true}
                            rowClassName={rowClassName}
                            sticky={false}
                            pagination={{
                                onChange: onChangePage,
                                current: currentPage,
                                pageSize: currentPageSize,
                                pageSizeOptions: [50, 100, 150],
                                position: ['topRight', 'bottomRight'],
                                total:totalPages,
                            }}
                            expandable={{
                                // expandedRowRender,
                                expandIcon,
                            }}
                            onChange={handleChange}
                            onRow={(record) => {
                                return {
                                    onDoubleClick: () => {
                                        setOrderToEdit(record);
                                        setOpenEdit(true)
                                    },
                                };
                            }}
                        />


                    </ConfigProvider>
                </TableWrapper >

                <Modal
                    title="Сохранить маршрут"
                    open={openSaveRoute}
                    onCancel={closeModalSaveRoute}
                    footer={null}
                    destroyOnClose={true}
                    width={450}
                    style={{ top: 10 }}
                >
                    <SaveRouteModal saveRoute={saveRoute} setOpenSaveRoute={setOpenSaveRoute} openNotification={openNotification} userCompany={userCompany} />
                </Modal>

                <Modal
                    title="Выберите дату"
                    open={modalDate}
                    onCancel={closeModalmodalDate}
                    footer={null}
                    destroyOnClose={true}
                    width={350}
                    style={{ top: 10 }}
                    afterClose={gettingOrders}
                >
                    <ChooseDate data={createAgainData.current} closeModalmodalDate={closeModalmodalDate} openNotification={openNotification} />
                </Modal>
            </>
        );
    };

    return (
        <DataTable />
    );

};