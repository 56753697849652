import React from 'react';
import { IMaskInput } from 'react-imask';

const PayDayMaskInput = React.forwardRef((props, ref) => {
    const { value, onChange, ...rest } = props;
    return (
        <IMaskInput
            {...rest}

            onAccept={(value) => {
                if (onChange) {
                    onChange({ target: { name: props.name, value } });
                }
            }}
            inputRef={ref}
            mask={Number}
            value={value.toString()}
            min={1}
            max={31}
            style={{
                'width': '100%',
                'borderWidth': '1px',
                'borderColor': 'rgb(231,231,231)',
                'borderRadius': '6px',
                'transition': 'all 0.2s',
                'padding': '4px 11px',
                'color': 'rgba(0, 0, 0, 0.88)',
                'fontSize': '14px',
            }}
        />
    );
});

export default PayDayMaskInput;